import React, { useMemo } from "react"

interface TinyMCEViewerProps {
    content: string;
    className?: string;
}

const TinyMCEViewer = ({content, className}: TinyMCEViewerProps) => {

    const parsed = useMemo(() => {
        if (!content) {
            return null;
        }
        
        let parsedContent = content;

        const ads = [
            {find: "ad-1", tag: "23199893586", height: 250},
            {find: "ad-2", tag: "23199962653", height: 90},
            {find: "ad-3", tag: "23199964897", height: 250},
            {find: "ad-4", tag: "23199895755", height: 90},
        ]

        ads.forEach((ad) => {
            const regex = new RegExp(String.raw`<table [^>]*>((?!table).)*#${ad.find}#((?!table).)*<\/table>`, "gs")
            const adReplacement = `<div data-fuse="${ad.tag}" style="min-height: ${ad.height}px; margin-top:30px; margin-bottom:30px;"></div>`
            
            parsedContent = parsedContent.replaceAll(regex, adReplacement);
        })

        return parsedContent;
    }, [content])

    if (!parsed) {
        return null;
    }

    return (
        <div className={className} dangerouslySetInnerHTML={{ __html: parsed }} />
    )
}

export default TinyMCEViewer;